@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

a:hover {
    text-decoration: none;
}

.nav-width {
    width: 230px;
}

.content-width {
    width: calc(100% - 230px);
}

.table thead th {
    border-top: none;
    border-bottom: none;
}

.ui-timepicker-input {
    width: 105px;
}

.input-field {
    font-family: 'Roboto', serif;
    font-size: 15px;
    color: #777777 !important;
    box-shadow: none !important;
    border-color: #777777 !important;
}

.show-password-icon {
    float: right;
    margin-right: 10px;
    margin-top: -29px;
    position: relative;
}

.show-password-icon > img {
    width: 15px;
    cursor: pointer;
}

.blue-button {
    font-family: 'Roboto', serif;
    font-size: 15px;
    background: #0a84ff;
}

.blue-button:hover {
    background: #0a64ff;
}

/*toggle switch button*/
.buttons_switch {
    background: #0a84ff;
    display: inline-block;
    height: 42px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    text-align: center;
    width: 100%;
    padding: 0 1px;
}

.buttons_switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.buttons_switch .switch_left, .buttons_switch .switch_right {
    color: white;
    cursor: pointer;
    height: 42px;
    line-height: 43px;
    font-size: 14px;
    transition: 0.2s;
    position: absolute;
    width: 50%;
    z-index: 2;
}

.buttons_switch .switch_left {
    left: 0;
    bottom: 0;
}

.buttons_switch .switch_right {
    right: 0;
    bottom: 0;
}

.buttons_switch .bg_switch {
    background: white;
    border-radius: 10px;
    height: 38px;
    margin-left: 50%;
    position: absolute;
    transition: 0.5s;
    top: 2px;
    width: 49%;
    z-index: 1;
}

.buttons_switch input[type=checkbox]:checked ~ .bg_switch {
    margin-left: 1px;
}

.buttons_switch input[type=checkbox]:checked ~ .switch_left {
    color: black;
}

.buttons_switch input[type=checkbox]:not(:checked) ~ .switch_right {
    color: black;
}

/*dashboard styles*/
.bg {
    background: #fafafa;
}

.nav-link {
    color: gray;
    padding-top: 12px;
    padding-bottom: 12px;
}

.active-link {
    color: black !important;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #fafafa;
}

.back-button {
    font-size: 23px;
    background: white;
    padding: 8.5px 13px;
    border-radius: 5px;
    color: #0a84ff;
    margin-right: 10px;
}

.font-roboto {
    font-family: 'Roboto', serif;
}

.link-color {
    color: #0a84ff;
}

#gym-data-form #logo_url {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.color-picker {
    padding: 0;
    width: 145px;
    height: 145px;
    border-radius: 5px;
}

.color-picker::-webkit-color-swatch {
    padding: 0;
    border: none;
    border-radius: 5px;
}

.color-picker::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 5px;
    padding: 0;
}

.disabled {
    background: #c7c7c7;
}

/*width and height classes*/
.width-230 {
    width: 230px;
}

.width-320 {
    width: 320px;
}

.height-43 {
    height: 43px;
}

.height-48 {
    height: 48px;
}

.height-32 {
    height: 32px;
}

.height-57 {
    height: 57px;
}

.max-height-65 {
    max-height: 65px;
}

/*colors*/
.light-brown {
    background: #dbdbdb;
}

.light-gray {
    color: #777777;
}

.light-gray2 {
    background: #cacaca;
}

.bg-light-gray {
    background: #f2f2f2;
}

.middle-gray {
    background: #8d8d8d;
}

.bg-custom-blue {
    background: #0a84ff;
}

.bg-blue {
    background: #3200f9;
}

.bg-yellow {
    background: #e8ff00;
}

.bl-blue {
    border-left: 5px solid #0a84ff;
}

.bl-yellow {
    border-left: 5px solid #e8ff00;
}

.invisible-gray {
    color: #989898;
}

.big-circle {
    height: 158px;
    width: 158px;
}

.dot-border {
    border-width: 1px;
}

input[type='radio']:checked {
    background-image: none;
}

.template {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
    border-radius: 20px;
}

.shadow {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
}

.ticket-text-wrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/*slick styles*/
.slick-list {
    width: 100%;
}

.slick-track {
    margin-left: 0;
}

.w-break-line-height {
    word-break: break-all;
    overflow: hidden;
    line-height: 1.3;
}

.w-break {
    word-break: break-all;
    overflow: hidden;
}

.filter-checkbox label span {
    padding: 11px 20px;
    display: block;
    cursor: pointer;
}

.filter-checkbox label input {
    position: absolute;
    top: -20px;
}

.filter-checkbox input:checked + span {
    background-color: #0a84ff;
    color: #fff;
}

.border-blue {
    border: 1px solid #3200f9;
}

.search-icon {
    float: right;
    margin-right: 10px;
    margin-top: -29px;
    position: relative;
}

.search-icon img {
    width: 15px;
    cursor: pointer;
}

.counter-badge {
    padding-top: 8px;
    padding-bottom: 9px;
    width: 33px;
}

/*tables styling*/
.custom-table tbody tr.odd {
    background: white;
}

.custom-table tbody tr.even {
    background: #f1f1f1;
}

.no-borders td {
    border-top: 0;
    border-bottom: 0;
}

.scaling tbody tr:hover {
    transform: scale(1.01);
    transition: .4s;
    cursor: pointer;
    background: white;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.no-header thead th {
    padding: 0;
}

#messages-table tbody tr.odd {
    background: #f2f2f2;
}

#messages-table tbody tr.even {
    background: white;
}

#customers-table thead tr th {
    color: black;
}

#dashboard-sales-table tr td:not(.dataTables_empty) {
    padding-top: 9px;
    padding-bottom: 9px;
}

#gym-admins-table thead tr th {
    color: black;
    font-size: 14px;
}

#notes-table tbody tr.odd {
    background: #f2f2f2;
}

#notes-table tbody tr.even {
    background: white;
}

.dataTables_wrapper .dataTables_info {
    padding-top: 0 !important;
    font-family: 'Roboto', serif;
    color: #777777;
    margin-right: 10px;
    font-size: 14px;
}

.dataTables_wrapper .paginate_button.disabled {
    background: none;
}

.dataTables_wrapper .paginate_button.previous {
    margin-right: 10px;
}

.dataTables_wrapper .page-link {
    padding: 3px 10px;
}

@media only screen and (max-width: 1024px) {
    .nav-width {
        width: 200px;
    }

    .content-width {
        width: calc(100% - 200px);
    }
}

.im,.im p{
    color: #718096!important;
}
